import React, { useEffect } from 'react'
import * as S from '../ofertas/styles'
import { graphql } from 'gatsby'
import Layout from '../../../components/totem/layout'
import BtnVoltar from '../../../components/totem/Voltar/index'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { arrayChunk } from '../../../helpers/util'
import { ditoTrack } from '../../../helpers/dito'

const OfertasTotem = ({
  data: {
    allTotemOferta: { edges: ofertas },
  },
  pageContext: { loja },
}) => {

  useEffect(() => {
    ditoTrack('acesso-ofertas')
  }, [])
  
  const OfertaClick= (teste, nomeOferta) => {
    const items = !!localStorage.getItem("Ofertas");
    if(items == true){
      localStorage.removeItem("Ofertas", teste);
      localStorage.removeItem("nome oferta", nomeOferta);
      localStorage.setItem("Ofertas", teste);
      localStorage.setItem("nome oferta", nomeOferta);
    } else{
      localStorage.setItem("nome oferta", nomeOferta);
      localStorage.setItem("Ofertas", teste);
    }
  };

  return (
    <Layout loja={loja}>
      <S.OfertasTotem>
        <div className="title">
          <h1>
            ESCOLHA A OFERTA<br></br> QUE QUER PEGAR AGORA
          </h1>
        </div>
        {arrayChunk(ofertas, 2).map((chunk, k) => (
          <div className="container" key={`oferta-${k}`}>
            {chunk.map(({ node: oferta }) => (
              <S.OfertasTotemBox key={oferta.id}>
                <GatsbyImage
                  alt={oferta.titulo}
                  image={getImage(oferta.localImagem)}
                />
                <p>{oferta.titulo}</p>
                <S.NavLink 
                  to={`/totem/${loja.slug}/ofertas/${oferta.slug}`} 
                  id={`btn-${oferta.slug}`}
                  onClick={(e) => OfertaClick(oferta.oferta, oferta.slug, e)}
                >
                  ATIVAR OFERTA
                </S.NavLink>
              </S.OfertasTotemBox>
            ))}
          </div>
        ))}
      </S.OfertasTotem>
      <BtnVoltar></BtnVoltar>
    </Layout>
  )
}

OfertasTotem.propTypes = {
  data: PropTypes.shape({
    allTotemOferta: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            titulo: PropTypes.string.isRequired,
            oferta: PropTypes.string.isRequired,
          }),
        })
      ),
    }),
  }),
  pageContext: PropTypes.shape({
    loja: PropTypes.shape({
      nome: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}
export default OfertasTotem

export const query = graphql`
  query ($language: String!, $slugLoja: [String]) {
    allTotemOferta(
      filter: { lojas: { in: $slugLoja }, alternative_id: { ne: 0 } }
      sort: { fields: ordem }
    ) {
      edges {
        node {
          id
          localImagem {
            name
            childImageSharp {
              gatsbyImageData(
                width: 265
                height: 157
                transformOptions: { cropFocus: CENTER }
                placeholder: BLURRED
                quality: 70
              )
            }
          }
          slug
          titulo
          oferta
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
