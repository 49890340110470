import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-i18next'



export const OfertasTotem = styled.div`
  background-color: #c4ddf2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 102vh;
  width: 100%;
  margin-top: -80px;
  background-position: center;
  background-size: cover;

  .title {
    padding: 0px 60px 0 60px;
    text-align: center;
    padding-bottom: 30px;
    h1 {
      color: #001e62;
      font-style: italic;
      font-weight: 700;
      font-size: 30px;
      line-height: 32px;
      font-family: 'ASICSFont3.0-Bold', sans-serif;
    }
  }

  .container{
    padding: 15px 50px;
    display: flex;
    justify-content: center;
    gap: 30px;
  }

  @media (max-width: 767px){
    padding-right: 0;
    .title{
      padding: 200px 15px 30px;
    }
    .container{
      padding: 15px 30px;
      gap: 25px;
      flex-direction: column;
    }
  }
  @media (max-width: 450px){
    .title{
      padding: 110px 15px 0px;
      h1{
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
`

export const OfertasTotemBox = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 11.1982px rgba(0, 0, 0, 0.15);
    border-radius: 16.383px;
    padding: 15px 10px 45px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 289px;
    text-align: center;
    z-index: 9;
    img{
        padding-bottom: 40px;
    }
    p{
        color: #001E62;
        font-size: 24.1433px;
        font-style: italic;
        line-height: 33px;
        padding-bottom: 10px;
        padding-top: 15px;
        font-family: 'ASICSFont3.0-Regular', sans-serif;
        strong{
            font-weight: 700;
            font-family: 'ASICSFont3.0-Bold', sans-serif;
        }
        
    }
    @media (max-width: 450px){
      width: 300px;
      height: 250px;
      padding: 15px 20px;
      img{
        padding-bottom: 0px;
      }
      p{
        font-size: 16px;
        line-height: 18.052px;
      }
    }
`   


export const NavLink = styled(Link)`
    border: 0.862262px solid #4A5FC4;
    border-radius: 47.6047px;
    text-decoration: none;
    color: #4A5FC4;
    text-align: center;
    padding: 8px 30px;
    font-family: 'ASICSFont3.0-Bold', sans-serif;

    &:hover{
      background: #4A5FC4;
      color: white;
      transition: background 0.4s;
    }
    @media (max-width: 450px){
      font-size: 14px;
      line-height: 14px;
      padding: 10px 14px;
    }
`

